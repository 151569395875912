<!--页面代码从这里开始拷贝-->
<template>
  <div>
    <table-compnent ref="table" :records="tableData" @resetHandler="reset" @searchHandler="doSearch"
      @batchDeleteHandler="batchDelete" @addHandler="saveTripDriver" @exportExl="exportExl" :loading="loading"
      :showDelete="false" :showAdd="canAdd" :EShow="true" :exportLoading="exportLoading" exportText="批量下载二维码">
      <template v-slot:search>
        <el-form-item label="姓名:">
          <el-input v-model.trim="search.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input v-model.trim="search.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="search.accountApplyStatus" clearable placeholder="请选择状态">
            <el-option v-for="(item, index) in states" :key="index" :label="item.name" :value="item.no" />
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号:">
          <el-input v-model.trim="search.carNumber" placeholder="请输入车牌号"></el-input>
        </el-form-item>

        <el-form-item label="所属公司:" v-if="isAdmin">
          <el-input v-model.trim="search.companyName" placeholder="请输入所属公司"></el-input>
        </el-form-item>
        <el-form-item label="商户号:">
          <el-input v-model.trim="search.huifuId" maxlength="5" placeholder="请输入商户号后五位查询"></el-input>
        </el-form-item>

        <el-form-item label="注册日期">
          <el-date-picker v-model="registerDate" format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="registerDateChange">
          </el-date-picker>
        </el-form-item>
      </template>
      <template v-slot:buttons>
        <el-button type="primary" @click="exportDriverExl" :loading="exportSearchLoading">导出查询结果</el-button>
      </template>
      <template v-slot:content>
        <el-table-column type="selection" width="55" :selectable="rowSelectable" />
        <el-table-column prop="name" label="姓名">
          <template scope="s">
            <el-button type="text" @click="toDetail(s.row.no)">{{
                s.row.name
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号" width="180"></el-table-column>
        <el-table-column prop="carNumber" label="车牌号"></el-table-column>
        <el-table-column prop="mobile" label="手机号"></el-table-column>
        <el-table-column prop="companyName" label="所属公司" v-if="isAdmin"></el-table-column>
        <!-- <el-table-column prop="nativePlace" label="籍贯"></el-table-column> -->
        <!-- <el-table-column prop="divisionAge" label="司龄(年)"></el-table-column>
        <el-table-column prop="carType" label="准驾车型"></el-table-column> -->
        <el-table-column prop="motorcadeName" label="所属车队"></el-table-column>
        <el-table-column prop="huifuId" label="商户号"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.accountApplyStatusName }}
            <el-tooltip class="item" effect="dark" placement="bottom-start" v-if="scope.row.accountApplyStatus == 1">
              <div style="max-width: 229px" slot="content">
                {{ scope.row.reason }}
              </div>
              <el-button class="aaac">?</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="注册时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="freeze(scope.row)"
              v-if="[3, 4].includes(scope.row.accountApplyStatus)">冻结</el-button>
            <el-button type="text" size="small" @click="unfreeze(scope.row)" v-if="scope.row.accountApplyStatus == 5">解冻
            </el-button>
            <el-button type="text" size="small" @click="auditDriver(scope.row)"
              v-if="scope.row.accountApplyStatus == 2">审核</el-button>
            <el-button type="text" size="small" @click="doAccout(scope.row)"
              v-if="[3, 4].includes(scope.row.accountApplyStatus)">{{
                  scope.row.accountApplyStatus | accountState
              }}</el-button>
            <el-button type="text" size="small" @click="createQr(scope.row)"
              v-if="[3, 4].includes(scope.row.accountApplyStatus)">
              下载二维码</el-button>
            <el-button type="text" size="small" @click="queryById(scope.row.id)"
              v-if="canEdit && [3, 4].includes(scope.row.accountApplyStatus)">编辑</el-button>
            <el-button type="text" size="small" @click="deleteById(scope.row.id)"
              v-if="[1, 2, 5].includes(scope.row.accountApplyStatus)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </table-compnent>
    <el-dialog title="司机审核" width="600px" :visible.sync="showAuditDialog" @closed="showAuditDialog = false"
      :close-on-click-modal="false">
      <el-form v-model="audit" label-width="100px">
        <el-form-item label="司机姓名">
          {{ audit.userName }}
        </el-form-item>
        <el-form-item label="审核结果">
          <el-radio-group v-model="audit.isPass">
            <el-radio :label="true">审核通过</el-radio>
            <el-radio :label="false">审核不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="不通过原因" required v-if="!audit.isPass">
          <el-input type="textarea" :rows="4" style="width: calc(100% - 50px)" v-model="audit.reason" maxlength="50"
            show-word-limit>
          </el-input>
        </el-form-item>
        <div class="operate">
          <el-button type="primary" @click="submitAudit">提交</el-button>
          <el-button @click="showAuditDialog = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="冻结司机" width="600px" :visible.sync="showfreezeDialog" @closed="showfreezeDialog = false"
      :close-on-click-modal="false">
      <el-form v-model="audit" label-width="100px">
        <el-form-item label-width="0">
          <el-input type="textarea" v-model="freezeReason" maxlength="50" placeholder="请输入冻结原因" :rows="4" />
          <span
            style="color:red;margin-top: 6px;display: inline-block;line-height: 20px;">*&nbsp;司机冻结后，将不能继续接单与收款，账户可正常取现。请您提前与司机沟通并达成一致后，再操作冻结。</span>
        </el-form-item>
        <div class="operate">
          <el-button type="primary" @click="submitFreeze">提交</el-button>
          <el-button @click="showfreezeDialog = false">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
  
<script>
import baseList from '../../base/listPage.vue';
import TripDriverApi from "@/api/TripDriverApi";
import { CommonDateFormat } from "@/utils/DateUtils";
export default {
  name: "TripDriverList",
  extends: baseList,
  data() {
    return {
      tableData: {},
      registerDate: [],
      search: {
        name: "",
        mobile: "",
        carNumber: "",
        huifuId: "",
        companyName: "",
        startTimeStr: "",
        endTimeStr: "",
        accountApplyStatus: null,
      },
      page: {},
      loading: true,
      exportLoading: false,
      existsCompanyNo: false,
      states: [],
      showAuditDialog: false,
      audit: {
        id: null,
        userName: "",
        isPass: true,
        reason: "",
      },
      showfreezeDialog: false,
      freezeRow: null,
      freezeReason: "",
      isCache: false,
      exportSearchLoading: false,
      downQrCodeLoading: false,
    };
  },
  filters: {
    accountState(val) {
      switch (val) {
        case 0:
          return "";
        case 1:
          return "结算配置";
        case 2:
          return "结算配置";
        // case 3:
        //   return "";
        // case 4:
        //   return "变更开户信息";
        // case 5:
        //   return "变更开户信息";
        default:
          return "结算配置";
      }
    },
    dateFormat(val) {
      if (isNaN(val)) return "";
      return CommonDateFormat(new Date(val));
    },
  },

  created() {
    this.existsCompanyNo = this.$store.state.user.companyNo != null &&
      this.$store.state.user.companyNo != "";
    this.findStates();
  },
  methods: {
    reset() {
      this.search = this.$options.data().search;;
      this.registerDate = [];
      this.registerDateChange();
      this.doSearch({ pageSize: 20, pageNum: 1 });
    },
    doSearch(params) {
      this.loading = true;
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      this.search.accountApplyStatus =
        this.search.accountApplyStatus == ""
          ? null
          : this.search.accountApplyStatus;
      TripDriverApi.search(this.search).then((resp) => {
        if (resp.code === "200") {
          // console.log(resp.data);
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    toDetail(no) {
      this.navTo(`/TripDriverDetail/${no}`);
    },
    saveTripDriver() {
      this.navTo("/saveTripDriver");
    },
    queryById(id) {
      TripDriverApi.queryTripDriverById({ id: id }).then((resp) => {
        console.log(resp);
        if (resp.code === "200") {
          this.queryParam("saveTripDriver", resp.data);
        }
      });
    },
    deleteById(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        TripDriverApi.deleteTripDriver({ id: id }).then((resp) => {
          if (resp.code === "200") {
            this.doSearch(this.page);
            this.$successMsg(resp.msg);
          } else {
            this.$errorMsg(resp.msg);
          }
        });
      });
    },
    batchDelete(params) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        TripDriverApi.batchDeleteTripDriver(params).then((resp) => {
          if (resp.code === "200") {
            this.$successMsg(resp.msg);
            this.doSearch(this.page);
          } else {
            this.$errorMsg(resp.msg);
          }
        });
      });
    },
    downQr(row) {
      if (row.qrCodePath) {
        let a = document.createElement("a");
        a.style.display = "none";
        a.download = "123.png";
        a.href = row.qrCodePath;
        document.body.appendChild(a);
        a.click();
      } else {
        this.$errorMsg("请先生成二维码");
      }
    },
    createQr(row) {
      TripDriverApi.genDriverCode(row.no).then((resp) => {
        console.log(resp);
        if (resp.code === "200") {
          if (row.qrCodePath == "") {
            row.qrCodePath = resp.data;
          }
          let a = document.createElement("a");
          a.style.display = "none";
          a.download = "123.png";
          a.href = resp.data;
          document.body.appendChild(a);
          a.click();
          this.$successMsg("下载成功");
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    doAccout(row) {
      this.navTo(`/tripdriveraccout/${row.id}`);
    },
    registerDateChange(date) {
      if (date) {
        this.search.startTimeStr = CommonDateFormat(
          new Date(date[0]),
          "YYYY-mm-dd"
        );
        this.search.endTimeStr = CommonDateFormat(
          new Date(date[1]),
          "YYYY-mm-dd"
        );
      } else {
        this.search.startTimeStr = "";
        this.search.endTimeStr = "";
      }
    },
    exportExl() {
      let that = this;
      let ids = this.$refs.table.multipleSelection;
      if (ids == undefined || ids.length == 0) {
        this.$errorMsg("请先选择一条记录，再进行当前操作");
        return;
      }
      if (this.exportLoading) {
        this.$message("请勿重复操作");
        return;
      }
      this.exportLoading = true;
      let driverNoList = this.tableData.records
        .filter((p) => ids.includes(p.id))
        .map((p) => p.no);
      TripDriverApi.batchDownloadDriverQrCode({
        driverNoList: driverNoList,
      })
        .then((res) => {
          this.exportLoading = false;
          if (res.status == 200) {
            let blob = new Blob([res.data]);
            let cd =
              res.headers["Content-Disposition"] ||
              res.headers["content-disposition"];
            if (cd == undefined || cd == null) {
              let reader = new FileReader();
              reader.addEventListener("loadend", function () {
                let resp = JSON.parse(reader.result);
                that.$errorMsg(resp.msg);
              });
              reader.readAsText(blob, "utf-8");
              return;
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            let fileName = decodeURIComponent(
              cd.replace("attachment; filename=", "")
            );
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            this.$successMsg("导出成功");
          } else {
            this.$errorMsg(res.msg);
          }
        })
        .catch((err) => {
          this.$errorMsg(err);
          this.exportLoading = false;
        });
    },

    exportDriverExl() {
      let that = this;
      if (this.exportSearchLoading) {
        return;
      }
      this.exportSearchLoading = true;
      TripDriverApi.exportDriverList(this.search)
        .then((res) => {
          this.exportSearchLoading = false;
          if (res.status == 200) {
            let blob = new Blob([res.data]);
            let cd =
              res.headers["Content-Disposition"] ||
              res.headers["content-disposition"];
            if (cd == undefined || cd == null) {
              let reader = new FileReader();
              reader.addEventListener("loadend", function () {
                let resp = JSON.parse(reader.result);
                that.$errorMsg(resp.msg);
              });
              reader.readAsText(blob, "utf-8");
              return;
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            let fileName = decodeURIComponent(
              cd.replace("attachment; filename=", "")
            );
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            this.$successMsg("导出成功");
          } else {
            this.$errorMsg(res.msg);
          }
        })
        .catch((err) => {
          this.$errorMsg(err);
          this.exportSearchLoading = false;
        });
    },
    rowSelectable(row, index) {
      return ![null, 0, 1, 2].includes(row.accountApplyStatus);
    },
    auditDriver(row) {
      console.log(row);
      this.audit = {
        id: row.id,
        userName: row.name,
        isPass: true,
        reason: "",
      };
      this.showAuditDialog = true;
    },
    submitAudit() {
      if (!this.audit.isPass && !this.audit.reason) {
        this.$errorMsg("不通过原因不能为空");
        return;
      }
      TripDriverApi.auditDriver(this.audit)
        .then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.$successMsg("修改成功");

            let currentRow = this.tableData.records.find(
              (p) => p.id == this.audit.id
            );
            if (res.data.isPass) {
              currentRow.accountApplyStatusName = "审批通过";
              currentRow.accountApplyStatus = 3;
              currentRow.huifuId = res.data.huifuId;
            } else {
              currentRow.accountApplyStatusName = "审批不通过";
              currentRow.accountApplyStatus = 1;
              currentRow.reason = res.data.reason;
            }

            this.showAuditDialog = false;
          } else {
            this.$errorMsg(res.msg);
          }
        })
        .catch((err) => {
          this.$errorMsg(err + "err");
        });
    },
    findStates() {
      TripDriverApi.findAccountApplyStatusSelect()
        .then((res) => {
          // console.log(res);
          if (res.code == "200") {
            this.states = res.data;
          } else {
            this.$errorMsg(res.msg);
          }
        })
        .catch((err) => {
          this.$errorMsg(err);
        });
    },
    freeze(row) {
      this.freezeRow = row;
      this.showfreezeDialog = true;
    },
    submitFreeze(isFreeze = true) {
      if (!this.freezeRow) {
        this.$errorMsg('请选择司机后在操作');
        return;
      }
      if (isFreeze && (this.freezeReason == null || this.freezeReason.length == 0)) {
        this.$errorMsg('请输入冻结原因');
        return;
      }
      TripDriverApi.frozenDriver({
        driverNo: this.freezeRow.no,
        reason: this.freezeReason,
        type: isFreeze ? 1 : 0
      })
        .then((res) => {
          console.log(res);
          if (res.code == "200") {
            this.freezeRow.accountApplyStatus = res.data.accountApplyStatus;
            this.freezeRow.accountApplyStatusName = res.data.accountApplyStatusName;
            this.$successMsg((isFreeze ? "冻结" : "解冻") + '成功')
          } else {
            this.$errorMsg(res.msg);
          }
          if (isFreeze) {
            this.freezeReason = "";
            this.showfreezeDialog = false;
          }

        })
        .catch((err) => {
          this.$errorMsg(err);
          this.freezeReason = "";
        });
    },
    unfreeze(row) {
      this.freezeRow = row;
      this.$confirm(`您正在解冻司机${row.name}<p style="color:red;height:0;margin-bottom:20px;">司机解冻后，司机可以正常接单与收款</p>`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        type: "warning",
      }).then(() => {
        this.submitFreeze(false);
      })
    },
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-date-editor--daterange {
  width: 220px;
}

::v-deep .el-dialog__body {
  border-top: 1px #e9e9e9 solid;
  padding-bottom: 10px;

}

::v-deep .operate {
  width: 100%;
  margin: 0px 0 10px -20px;
  padding: 0 20px;
  border-top: 1px #e9e9e9 solid;
  display: flex;
  justify-content: flex-end;

  .el-button {
    height: 40px;
    margin-top: 20px;
  }
}

.aaac {
  font-size: 12px;
  display: inline-block;
  padding: 0;
  margin-left: 8px;
  margin-right: 17px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  font-style: normal;
  color: #c0c4cc;
  border: 1px solid #c0c4cc;
  border-radius: 50%;
  text-align: center;
}
</style>